@font-face {
  font-family: "Kanit-Regular";
  src: url(/static/media/Kanit-Regular.6e70a0fe.ttf) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "LuckiestGuy-Regular";
  src: url(/static/media/LuckiestGuy-Regular.04dea529.ttf) format("truetype");
  font-weight: normal;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Kanit-Regular";
  background-color: #f9e2c4;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
a {
  text-decoration: none;
  color: unset;
}
a:hover {
  color: unset;
}
.cursor-pointer {
  cursor: pointer;
}

/* Header Section */
.header-section {
  width: 100%;
  height: 3.75rem;
  background: #f9e2c4;
}
.logo-text {
  font-family: "LuckiestGuy-Regular";
  font-size: 1.68rem;
  color: rgb(128 195 165);
}
.logo-text > span {
  color: #fff;
}
.connect-button {
  color: #FFFFFF;
  background: #000000;
  border-radius: 16px;
  padding: 0.4rem 1.5rem;
  outline: none;
  border: none;
}
@media screen and (max-width: 415px) {
  .header-section {
    height: 6rem;
  }
  .right-header-holder {
    justify-content: end;
  }
  .account-address {
    margin-right: 14px;
  }
}

/* Main content Section */

.main-content-section {
  width: 100%;
  min-height: 1110px;
}
.top-info-bnbyields > h1 {
  font-size: 2rem;
  color: #1e2329;
  margin-bottom: 1.5rem;
}
.top-info-bnbyields > p {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #1e2329;
}

.total-info-box {
  background: #12161c;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 1rem 2rem;
  min-height: 150px;
}
.total-info-title {
  font-size: 1.43rem;
  color: #ffffff;
}
.total-info-value {
  font-size: 1.93rem;
  color: rgb(128 195 165);
}
.total-info-dollor {
  font-size: 0.93rem;
  color: #afafaf;
}
.main-btn {
  background: #000000;
  border-radius: 16px;
  border: none;
  font-size: 1rem;
  color: #ffffff;
  padding: 1rem 0rem;
}
.account-address {
  color: #000;
}
.main-button-holder {
  margin: 2.5rem 0rem 2.5rem 0rem;
}
.right-main-section {
  background: #12161c;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 1rem;
  margin-bottom: 2.5rem;
}
.right-main-section1 {
  border-radius: 16px;
  margin-bottom: 2.5rem;
}
.farm-middle{
  grid-gap: 3rem;
  gap: 3rem;
}
.last-deposit-head {
  font-size: 1.43rem;
  color: #ffffff;
  margin: 1rem 0rem;
}
.deposit-holder {
  /* background: #0a0c0f;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25); */
  border-radius: 16px;
  padding: 1rem;
 
  overflow-y: scroll;
}
.deposit-holder::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.deposit-holder::-webkit-scrollbar {
  border-radius: 50px;
  width: 4px;
  background-color: #f5f5f5;
}

.deposit-holder::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #545452;
}

.deposit-item {
  background: #12161c;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 1rem;
}
.deposit-item-top .tx-hash {
  font-size: 1.12rem;
  color: rgb(128 195 165);
}
.deposit-item-top .tx-date {
  font-size: 0.87rem;
  color: #afafaf;
}
.deposit-item-bottom .wallet-address {
  font-size: 1.12rem;
  color: #afafaf;
}
.deposit-item-bottom .amount {
  font-size: 0.87rem;
  color: #afafaf;
}
.deposit-item-bottom .amount .deposit-value {
  color: rgb(128 195 165);
  font-weight: bold;
}

.deposit-item-bottom .plan {
  font-size: 0.87rem;
  line-height: 24px;
  color: #afafaf;
}
.deposit-item-bottom .plan .plan-number {
  color: rgb(128 195 165);
  font-weight: bold;
}

@media screen and (max-width: 1200px) {
  .left-main-section {
    width: 70%;
  }
}
@media screen and (max-width: 990px) {
  .left-main-section {
    width: 70%;
  }
  .right-main-section {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .deposit-item-top {
    flex-wrap: wrap;
    justify-content: center !important;
    text-align: center;
  }
  .deposit-item-bottom {
    flex-wrap: wrap;
    justify-content: center !important;
    text-align: center;
  }
}
@media screen and (max-width: 575px) {
  .left-main-section {
    width: 100%;
  }
}
/* Stake Section */
.stake-section {
  width: 100%;
  /* min-height: 900px; */
}
.left-stake-section {
  background: #12161c;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.45);
  border-radius: 16px;
  padding: 1rem;
}
.stake-head {
  font-weight: 600;
  font-size: 1.93rem;
  color: rgb(128 195 165);
}
.stake-head-title {
  font-size: 0.87rem;
  color: rgb(128 195 165);
  width: 100px;
  text-align: center;
}
.stake-text {
  font-size: 1rem;
  color: #ffffff;
  width: 100px;
  text-align: center;
}
.stake-arrow-holder {
  width: 100px;
  text-align: left !important;
}
.stake-item {
  border-top: 2px solid;
  padding: 2rem 0rem;
}
.stake-arrow {
  transition: all 0.3s;
  border: solid #fff;
  border-width: 0 1px 1px 0;
  float: left;
  color: #fff;
  margin-top: 6px;
  padding: 4px;
  transform: rotate(45deg);
  margin-left: 8px;
}
.stake-input {
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0.5rem 0rem;
  width: auto;
  color: #fff;
}
.stake-button {
  background: rgb(128 195 165);
  position: absolute;
  border: none;
  border-radius: 16px;
  height: 100%;
  padding: 0.5rem 1.8rem;
  top: 0;
  right: 0;
}
.stake-control-holder {
  position: relative;
  border-radius: 16px;
  border: 1px solid rgb(128 195 165);
  width: 250px;
  padding: 0.2rem 0.5rem;
}

.farm-information-box {
  background: #80bac3;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 1rem;
}
.farm-head {
  font-weight: 600;
  font-size: 1.93rem;
  color: #1e2329;
  margin-bottom: 2rem;
}
.farm-item-title {
  font-size: 0.87rem;
  color: #1e2329;
}
.farm-item{
  grid-gap: 5.5rem;
  gap: 5.5rem;
}
.farm-middle-value {
  font-size: 1.18rem;
  color: #1e2329;
}
.farm-dollor {
  font-size: 0.81rem;
  color: #1e2329;
}
.farm-middle-btn {
  border-radius: 16px;
  padding: 0.4rem 1.8rem;
  border: none;
  font-size: 1rem;
  width: 120px;
}
.farm-middle-btn.harvest {
  color: #000000;
}
.farm-middle-btn.history {
  background: #cb9b27;
  color: #fff;
}
.afflicate-box {
  background: #ffffff;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.18);
  border-radius: 16px;
  padding: 1rem;
  /*margin-bottom: 2.5rem;*/
}
.afflicate-head {
  font-weight: 600;
  font-size: 1.93rem;
  color: #1e2329;
}
.afflicate-subhead {
  font-size: 0.93rem;
  color: #1e2329;
}
.afflicate-rectangle {
  background: #dddddd;
  border-radius: 8px;
  padding: 0.5rem 1rem;
}
.rect-level {
  font-weight: 600;
  white-space: nowrap;
  color: #1e2329;
}
.rect-percent {
  font-weight: 600;
  color: #1e2329;
}
.afflicate-rectangle.aff1 {
  height: 183px;
}
.afflicate-rectangle.aff2 {
  height: 157px;
}
.afflicate-rectangle.aff3 {
  height: 127px;
}
.afflicate-rectangle.aff3 {
  height: 97px;
}
.rect-level.aff1 {
  font-size: 1rem;
}
.rect-level.aff2 {
  font-size: 0.87rem;
}
.rect-level.aff3 {
  font-size: 0.81rem;
}
.rect-percent.aff1 {
  font-size: 1.75rem;
}
.rect-percent.aff2 {
  font-size: 1.5rem;
}
.rect-percent.aff3 {
  font-size: 1rem;
}
.personal-link-box {
  background: #dddddd;
  border-radius: 16px;
  padding: 1rem;
}
.personal-link-head {
  font-size: 0.93rem;
  color: #1e2329;
}
.pers-link {
  font-size: 0.87rem;
  color: #1e2329;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 17px;
  width: 60%;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.pers-copy-btn {
  background: rgb(128 195 165);
  border: none;
  border-radius: 16px;
  height: 100%;
  padding: 1rem 2rem;
}
.person-inner-box {
  background: #ffffff;
  border-radius: 16px;
  font-size: 1rem;
  color: #000;
}
.afflicate-end-text {
  font-size: 0.93rem;
  color: #1e2329;
  margin-top: 0.7rem;
}
.read-more-box {
  background: #ffffff;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.18);
  border-radius: 16px;
  padding: 1.5rem 1rem;
  margin: 2rem 0rem 2.5rem 0rem;
}
.read-title {
  font-weight: 600;
  font-size: 1.87rem;
  color: #1e2329;
  margin-bottom: 0.5rem;
}
.read-body {
  font-weight: 500;
  font-size: 1rem;
  color: #1e2329;
}
@media screen and (max-width: 1400px) {
  .farm-item{
    grid-gap: 2rem;
    gap: 2rem;
  }
}
@media screen and (max-width: 1200px) {
  .right-stake-section {
    width: 50%;
  }
  .farm-head{
    text-align: center;
  }
  .farm-item{
    justify-content: space-evenly;
  }
  .total-info-box{
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .right-stake-section {
    width: 60%;
  }
  .left-stake-holder {
    width: 100%;
  }

}
@media screen and (max-width: 768px) {
  .right-stake-section {
    width: 70%;
  }
  .stake-arrow-holder {
    padding-left: 10px;
  }
  .farm-inner-item{
    width: 100%;
  }
}
@media screen and (max-width: 575px) {
  .right-stake-section {
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  /* .person-inner-box {
    flex-wrap: wrap;
  } */
  .pers-link {
    padding: 1rem 0rem;
  }
  /* .pers-copy-btn {
    width: 100%;
  } */
}
/* Footer Section */
.footer-main-section {
  background: rgb(128 195 165);
  width: 100%;
}
.footer-main-top > h2 {
  font-weight: 600;
  font-size: 1.43rem;
  color: #1e2329;
  margin-bottom: 0.5rem;
}
.footer-main-top > p {
  font-size: 1.43rem;
  color: #1e2329;
}
.footer-item-icholder {
  background: #1c1f23;
  border-radius: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.foote-item-icon {
  padding: 1.2rem;
}
.footer-item-title {
  font-weight: 600;
  font-size: 1.18rem;
  color: #1e2329;
  margin-top: 0.5rem;
}
.footer-item-text {
  font-size: 0.87rem;
  color: #1e2329;
  text-align: center;
  width: 90%;
}
.footer-bottom-section {
  background: #dadada;
}
.footer-audit-box {
  background: #ffffff;
  border-radius: 15px;
  width: 40%;
  margin: auto;
}
.footer-bottom-img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
}
.footer-bottom-img.social{
  height: 35px;
  width: unset;
}
.footer-bottom-img:hover{
  transform: scale(1.1,1.1);
}
.footer-BNBNEO {
  font-weight: 700;
  font-size: 0.62rem;
  color: #1e2329;
  text-align: center;
  margin-top: 0.7rem;
}
.footer-BNBNEO > span {
  font-weight: normal;
}
.footer-BNBNEO-bottom {
  font-weight: 600;
  font-size: 0.62rem;
  color: #1e2329;
  text-align: center;
  margin-top: 0.5rem;
}
@media screen and (max-width: 1200px) {
  .footer-item-holder {
    grid-gap: 2rem;
    gap: 2rem;
  }
  .footer-item-text {
    width: 50%;
  }
}
@media screen and (max-width: 786px) {
  .footer-item-text {
    width: 70%;
  }
  .footer-audit-box {
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  .footer-item-text {
    width: 90%;
  }
  .footer-main-top > h2 {
    font-size: 1.2rem;
  }
  .footer-main-top > p {
    font-size: 1.2rem;
  }
}

/* History Modal */
.modalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background: rgba(18, 22, 28, 0.6);
  -webkit-backdrop-filter: blur(41px);
          backdrop-filter: blur(41px);
}
.modal-holder {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  justify-content: center;
}
.history-modal {
  width: 500px;
  height: auto;
  background-color: #fff;
  padding: 2rem;
  border-radius: 20px;
  z-index: 100;
  background-color: rgba(255, 255, 255);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
}
.modal_header {
  position: relative;
}
.modal_header-title {
  font-weight: 600;
  font-size: 1.25rem;
  color: #1e2329;
  text-align: left;
}

.modal_content {
  padding-top: 2rem;
}
.modal_footer button {
  padding: 0.5rem;
  border-radius: 8px;
}
.modal-close {
  background-color: transparent;
  font-weight: 600;
}
.modal-close:hover {
  color: rgba(54, 67, 72, 0.8);
}
.modal_footer .submit {
  margin-right: 1rem;
  color: #fff;
}
.modal_footer .submit:hover {
  opacity: 0.8;
  color: #fff;
}

.modal-left {
  background: linear-gradient(
    179.44deg,
    rgba(0, 0, 0, 0.2) -26.48%,
    rgba(255, 255, 255, 0) 116.3%
  );
  border-radius: 22px;
}

.modal-left-box > h1 {
  font-size: 1.06rem;
  font-weight: bold;
  color: #ffffff;
}
.modal-left-box > img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.modal-left-box > p {
  font-weight: 600;
  font-size: 1.25rem;
  color: rgb(128 195 165);
  background: rgba(18, 21, 27, 0.6);
  border-radius: 23px;
  padding: 0.1rem 0.5rem;
}
.history-head-title {
  font-weight: bold;
  font-size: 0.81rem;
  color: #1e2329;
  width: 100px;
  text-align: center;
}
.historyItem {
  background: #d6d6d6;
  border-radius: 8px;
  padding: 0.7rem 0rem;
}
.historyItem > p {
  width: 100px;
  text-align: center;
  font-size: 0.81rem;
}
.history-item-holder {
  height: 184px;
  overflow-y: scroll;
}
.receipt-btn {
  background-color: rgb(128 195 165);
  padding: 0.3rem 1rem;
  color: #fff;
  border-radius: 10px;
}
.history-head {
  margin-right: 20px;
}
.min-text {
  color: #fff;
  font-size: 0.8rem;
}
.logo {
  height: 58px;
  background-repeat: no-repeat;
}
@media screen and (max-width: 695px) {
  .modal-left{
    flex-direction: row !important;
    width: 90%;
    justify-content: space-evenly !important;
  }
  .modal-left-box{
    text-align: center;
    justify-content: space-between !important;
  }
  .history-modal{
    width: 90%;
  }
  .modal-left-box > h1{
    white-space: nowrap;
  }
  
}
@media screen and (max-width: 400px) {
  .modal-left-box > h1{
    font-size: 0.9rem;
  }
  .modal-left-box > p{
    font-size: 1rem;
    white-space: nowrap;
  }
  .historyItem > p{
    width: 75px;
  }
  .historyItem{
    justify-content: center !important  ;
  }
  .history-head-title{
    width: 80px;
  }
  .history-head{
    margin-right: unset;
  }
}
@media screen and (max-width: 319px) {
  .container {
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .container::-webkit-scrollbar {
    display: none;
  }
}
.mainsection-content
{
  padding: 50px 45px;
}

.mainsection-content h1
{
  font-size: 5rem;
}
.header-holder
{
  border-bottom: 2px solid #dee2e6 !important;
  padding: 48px 0;
}

@media (min-width: 992px) and (max-width: 1200px)
{
  .mainsection-content h1 {
    font-size: 45px;
    font-weight: 400;
    line-height: 62px;
}
}
@media (min-width: 0px) and (max-width: 991px)
{
  .mainsection-content h1 {
    font-size: 36px;
    font-weight: 400;
    line-height: 50px;
}
}

.logo2
{
  width: 100%;
}
.align-center
{
  align-self: center;
}

.header-holder
{
  display: flex !important;
}
@media (min-width: 0px) and (max-width: 360px)
{
  .header-holder
  {
    display: block !important
  }
  .connect-button {
    margin: 12px 0 0 0;
}
}


.imageborder
{
  border-radius: 16px;
}
div.web3modal-modal-card {
  background-color: transparent;
  box-shadow: 0 0 60px #00000045;
  position: relative;
  overflow: visible;
  overflow: initial;
}
div.web3modal-modal-card::before {
  content: "Connect to a wallet";
  position: absolute;
  color: #fff;
  z-index: 100000;
  display: flex;
  font-size: 1.6rem;
  height: 2rem;
  top: -80px;
  left: 0;
  right: 0;
  padding: 1rem;
  justify-content: center;
  align-items: center;
}
div.web3modal-modal-card .web3modal-provider-wrapper {
  padding: 4px;
  border: none;
}
div.web3modal-modal-card .web3modal-provider-wrapper:not(:first-child) {
  padding-top: 0px;
}
div.web3modal-modal-card
  .web3modal-provider-wrapper:hover
  .web3modal-provider-container {
  background-color: #ffffff17;
}
div.web3modal-modal-card
  .web3modal-provider-wrapper:hover
  .web3modal-provider-icon
  img {
  transform: scale(1.2);
}
div.web3modal-modal-card
  .web3modal-provider-wrapper:hover
  .web3modal-provider-name {
  color: #fff;
}
div.web3modal-modal-card .web3modal-provider-container {
  flex-direction: row;
  background-color: rgb(128 195 165);
  border-radius: 12px;
  padding: 10px 12px;
  align-items: center;
  justify-content: center;
  border: none;
  transition: 0.3s;
  transition: all 0.3 ease-in;
  box-shadow: 0 0 100px 0px #0000001c;
  border: 1px solid #00000014;
}
div.web3modal-modal-card .web3modal-provider-icon {
  border-radius: 1rem;
  background: #0302055e;
  padding: 0.6rem;
  width: 60px;
  height: 60px;
  transition: 0.3s;
}
@media screen and (max-width: 768px) {
  div.web3modal-modal-card .web3modal-provider-icon {
    width: 8.5vw;
    height: 8.5vw;
    padding: 0;
  }
}
div.web3modal-modal-card .web3modal-provider-icon img {
  min-width: 2.5rem;
  width: 100%;
  height: 100%;
  transition: 0.3s;
}
div.web3modal-modal-card .web3modal-provider-name {
  margin-top: 0em;
  margin-left: 0.5em;
  text-align: left;
  transition: 0.3s;
  color: #fff;
}
div.web3modal-modal-card .web3modal-provider-description {
  display: none;
}
div.web3modal-modal-lightbox {
  background-color: #000000c9;
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  background-color: #0000008f;
  z-index: 1000000;
}
/*# sourceMappingURL=Connection.css.map */

@media screen and (max-width: 1475px) {
    .title-window {
        font-size: 30px;
    }

    .faq-block {
        padding: 17px 25px 25px 25px;
    }

    .close-faq-item {
        width: 30px;
        height: 30px;
    }

    .title-faq-item {
        font-size: 16px;
    }

    .item-faq {
        padding: 16px 0 16px 0;
    }

    .close-window {
        width: 30px;
        height: 30px;
        right: 0;
    }

    .info-warn {
        margin-left: 15px;
    }

    .warn-btn {
        margin-right: 15px;
    }
}

@media screen and (max-width: 1200px) {
    .container-logo,
    .container-logo-footer {
        margin-left: 15px;
    }

    .header-contacts,
    .container-list-contact {
        margin-right: 15px;
    }
}
@media screen and (min-width: 981px) {
    .container-first-screen .screen-block {
        align-items: center;
        flex-direction: row;
    }
    .container-first-screen .screen-block{
        min-height:50vh;
        padding-bottom: 150px;   width: auto;
    }
    .container-first-screen .screen-block:first-child{
        flex-grow: 1.5;
     
    }
    .first-screen,
    .section-info {
        position: relative;
    }
    /* .first-screen .bg-img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -2;
        background-repeat: repeat-x;
    }
    .first-screen::after,
    .section-info::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    .first-screen::after {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1));
    }
    .section-info::after{
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    } */
}
@media screen and (max-width: 980px) {
    .image-social{
        flex-direction: column;
    }
    .image-news .img-2,.img-3,.img-4{
        padding-top:5vh;
        flex:50% 1;
    }

    .img-2{
        width: 12rem !important;
        height: 6rem;
    }

    .image-news{
        flex-direction: column;
        align-items: center;
    }
    .main-title,
    .main-title span {
        font-size: 36px;
    }


    .first-screen .bg-img {
        display: none;
    }

    .sub-title-main,
    .sub-title-main span {
        font-size: 14px;
    }

    .pink-btn {
        width: 200px;
        height: 50px;
        font-size: 16px;
    }

    .sub-title-main {
        margin: 15px 0 40px;
    }

    .screen-block {
        min-height: 375px;
    }

    .result {
        font-size: 24px;
        margin-right: 15px;
    }

    .sub-text-result {
        font-size: 12px;
    }

    .block-result {
        padding: 13px 18px;
    }

    .first-screen {
        padding-bottom: 28px;
    }

    .section-info {
        padding-top: 100px;
    }

    .title-header {
        font-size: 30px;
    }

    .header-section {
        margin-bottom: 50px;
    }

    .container-calculate-info {
        margin: 0 15px;
    }

    .container-calculate-info {
        padding: 37px 25px 36px 25px;
        max-width: 856px;
    }

    .invest-btn {
        width: 225px;
        height: 40px;
    }

    .container-dashboard-info {
        margin: 0 15px;
        padding: 42px 25px 39px 25px;
        max-width: 857px;
    }

    .main-container-center {
        max-width: 100%;
        width: 95%;
    }

    .withdraw-btn {
        width: 210px;
        height: 45px;
    }
    .container-list-faq {
        max-height: 550px;
        overflow-y: auto;
        padding-right: 15px;
    }
}

@media screen and (max-width: 980px) and (orientation: landscape) {
    .container-list-faq {
        max-height: 213px;
        overflow-y: auto;
        padding-right: 15px;
    }

    .main-container-center {
        min-height: auto;
    }

    .container-window {
        padding: 20px;
        min-height: 286px;
    }
}

@media screen and (max-width: 920px) {
    .item-dashboard > div {
        padding: 14px 15px 14px;
        width: 152px;
        height: 46px;
    }

    .item-footer {
        max-width: 140px;
        margin-right: 15px;
    }

    .item-footer:last-child {
        margin-right: 0;
    }

    .footer-btn-contact {
        width: 150px;
        height: 35px;
        font-size: 13px;
    }
}

@media screen and (max-width: 890px) {
    .btn-contact {
        width: 100px;
        height: 30px;
        padding: 12px 30px;
    }

    .connect {
        margin-right: 10px;
    }

    .btn-contact span {
        font-size: 10px;
    }

    .item-link {
        width: 28px;
        height: 28px;
    }

    .discord .item-link {
        background-size: 10px 10px;
    }

    .twitter .item-link,
    .telegram .item-link {
        background-size: 10px 8px;
    }

    .header {
        padding: 12px 0 14px;
        min-height: 34px;
    }

    .container-slider {
        max-width: 500px;
    }

    .title-info {
        font-size: 12px;
    }

    .item-info p {
        font-size: 18px;
    }

    .list-info {
        width: 100%;
    }

    .item-dashboard > div {
        width: 140px;
    }

    .item-dashboard {
        margin-right: 15px;
    }

    .item-dashboard:last-child {
        margin-right: 0;
    }

    .warn-container {
        padding: 10px 0 6px;
        min-height: 50px;
    }

    .title-warn {
        font-size: 16px;
    }

    .info-warn p {
        font-size: 10px;
    }
}

@media screen and (max-width: 810px) {
    .item-dashboard > div {
        width: 110px;
    }

    .info-level {
        text-align: center;
    }

    .container-slider {
        max-width: 395px;
    }
}

@media screen and (max-width: 685px) {
    .container-slider {
        max-width: 365px;
    }

    .list-dashboard,
    .list-footer {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .item-dashboard,
    .item-footer {
        margin: 10px;
    }

    .container-dashboard-info {
        padding: 25px;
    }
}

@media screen and (max-width: 655px) {
    .container-info-screen {
        flex-direction: column;
        align-items: flex-start;
    }

    .screen-block {
        min-height: auto;
        width: 100%;
        padding-top: 8px;
    }

    .container-result {
        align-items: flex-start;
    }

    .mobile-block {
        display: none;
    }

    .mobile-open {
        margin: 0 auto;
        display: block;
        margin-top: 79px;
        width: 289px;
        height: 50px;
    }

    .container-info-screen {
        padding-top: 30px;
    }

    .container-counter {
        flex-direction: column;
    }
    .deposit-block{
        width: 100%;
    }
    .title-deposit {
        margin-bottom: 74px;
    }

    .deposit {
        width: 100%;
        min-height: 105px;
        margin-bottom: 25px;
    }

    .container-slider {
        max-width: 100%;
    }

    .container-counter-amount .title-deposit {
        margin-bottom: 28px;
    }

    .amount-field {
        width: 100%;
    }

    .container-footer-info {
        flex-direction: column;
    }

    .mobile-block-list {
        display: none;
    }

    .mobile-fade {
        display: flex;
        margin-right: 15px;
    }

    .mobile-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .container-footer-btn {
        width: 100%;
        margin-top: 17px;
    }

    .footer-btn-contact {
        margin-left: 15px;
    }
}

@media screen and (max-width: 650px) and (orientation: landscape) {
    .list-connect {
        margin: 18px 0 0;
    }

    .container-connect {
        min-height: 239px;
    }

    .container-window {
        padding: 15px;
        min-height: 204px;
    }

    .title-window {
        font-size: 20px;
    }

    .container-list-faq {
        max-height: 171px;
    }
}

@media screen and (max-width: 586px) {
    .container-header {
        flex-direction: column;
    }

    .container-logo {
        margin-bottom: 15px;
        max-width: 200px;
        width: 100%;
        margin-left: 0;
    }

    /* .logo,
    .logo img {
        width: 100%;
    } */

    .warn-container .container-info {
        flex-direction: column;
    }

    .warn-btn {
        align-self: flex-end;
    }

    .info-warn {
        max-width: 100%;
        margin-bottom: 10px;
    }

    .title-warn {
        margin-bottom: 5px;
    }

    .warn-container {
        padding: 10px 0 10px;
        min-height: 84px;
    }

    .info-warn {
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media screen and (max-width: 565px) {
    .item-dashboard:last-child {
        margin-right: 15px;
    }

    .list-info {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .item-info {
        justify-content: space-between;
        width: 48%;
        margin-top: 15px;
    }

    .item-info:first-child {
        margin-right: 15px;
    }

    .item-info:last-child {
        width: 100%;
    }

    .invest-btn {
        width: 270px;
        margin-top: 26px;
    }

    .title-header {
        font-size: 18px;
    }

    .header-section {
        margin-bottom: 30px;
    }

    .footer-counter {
        margin-top: 20px;
    }

    .item-dashboard,
    .item-footer {
        margin: 7px;
    }

    .item-dashboard:last-child {
        margin-right: 7px;
    }

    .item-dashboard > div {
        padding: 10px 10px 20px 10px;
    }

    .item-footer {
        max-width: 110px;
    }

    .title-footer-dashboard {
        margin-bottom: 20px;
    }

    .container-btn-contacts {
        margin: 0 10px;
    }

    .withdraw-btn {
        width: 180px;
        height: 40px;
    }

    .main-title,
    .main-title span {
        font-size: 30px;
    }

    .item-info {
        width: auto;
    }

    .list-info {
        justify-content: space-between;
    }

    .container-window {
        padding: 20px;
    }

    .title-window {
        font-size: 20px;
    }

    .list-connect {
        margin: 25px 0 0;
    }

    .container-connect {
        min-height: 246px;
        width: 90%;
    }

    .title-window {
        font-size: 30px;
    }

    .container-info-faq p {
        font-size: 10px;
    }

    .container-list-faq {
        max-height: 482px;
        overflow-y: auto;
        padding-right: 15px;
    }

    .faq-block {
        padding: 17px 10px 25px 20px;
    }
}

@media screen and (max-width: 376px) {
    .container-list-faq {
        max-height: 422px;
    }
    .contact-list .item-link {
        width: 42px;
        height: 42px;
        background-size: calc(100% - 24px);
        margin: 5px;
    }

    .btn-contact {
        width: 100%;
        height: 42px;
        margin: 0 auto 8px;
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
    }
    .pink-btn {
        height: 42px;
    }
    .pink-btn,
    .container-list-contact {
        width: calc(100% - 30px);
    }
    .sub-title-main {
        margin: 15px 0 25px;
        max-width: 95%;
    }

    .sub-title-main,
    .sub-title-main span {
        font-size: 12px;
    }

    .main-title,
    .main-title span {
        font-size: 24px;
    }

    .title-window {
        font-size: 20px;
    }

    .item-connect {
        margin-bottom: 10px;
    }

    .item-dashboard > div {
        padding: 10px;
        width: 108px;
    }

    .container-connect {
        min-height: 230px;
    }

    .item-dashboard,
    .item-footer {
        margin: 4px;
    }

    .title-faq-item {
        font-size: 14px;
    }

    .connect {
        margin-right: 5px;
    }

    .item-info p {
        font-size: 16px;
    }

    .container-dashboard-info {
        padding: 20px;
    }

    .title-footer-dashboard {
        font-size: 12px;
        line-height: normal;
    }

    .item-faq {
        padding: 10px 0 10px 0;
    }

    .container-list-faq {
        max-height: 384px;
    }

    .main-container-center {
        min-height: 200px;
    }
    .container-footer-info > div {
        flex-direction: column;
        align-items: center;
    }
    .container-info-screen {
        justify-content: flex-start;
    }
    .item-dashboard,
    .item-footer {
        flex-grow: 1;
        flex-shrink: 1;
        max-width: 100%;
        width: 100%;
    }
    .header-contacts {
        flex-direction: column;
    }
    .item-dashboard > div {
        width: calc(100% - 20px);
    }
    .footer .contact-list,
    .footer .container-logo,
    .container-logo-footer > span,
    .footer .container-list-contact,
    .footer .container-logo-footer,
    .footer .footer-btn-contact {
        margin: 8px auto;
    }
    .withdraw-btn,
    .mobile-open {
        max-width: calc(100% - 40px);
        margin-left: auto;
        margin-right: auto;
    }
    .invest-btn {
        width: 100%;
    }
    .block-result {
        width: 100%;
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center;
        text-align: center;
        padding-left: 0;
        padding-right: 0;
        margin-left: auto;
        margin-right: auto;
        padding-top: 7px;
        padding-bottom: 7px;
    }
    .block-result > p {
        margin: 0 auto 6px;
    }
    .sub-text-result {
        max-width: 100%;
    }
    .mobile-open {
        margin-top: 32px;
    }
    .header-contacts,
    .container-list-contact {
        margin-right: 0;
    }
    .container-btn-contacts .btn-contact{
        margin-left: 7px;
        margin-right: 7px;
    }
    .footer-btn-contact {
        font-size: 14px;
    }
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    /* font: inherit; */
    vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
    outline: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
    box-sizing: border-box;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none;
    -moz-appearance: none;
}

input[type=search] {
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: content-box;
}

textarea {
    overflow: auto;
    vertical-align: top;
    resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
    display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
    font-size: 100%; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
    -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
    outline: thin dotted;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
    outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
    border: 0; /* 1 */
    -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
    margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
    margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
    border: 0; /* 1 */
    padding: 0;
    white-space: normal; /* 2 */
    *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
    font-size: 100%; /* 1 */
    margin: 0; /* 2 */
    vertical-align: baseline; /* 3 */
    *vertical-align: middle; /* 3 */
    padding: 0;
    margin: 0;
    /* background: none; */
    border: none;
    outline: none;
    cursor: pointer;
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
    line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
    text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
    -webkit-appearance: button; /* 2 */
    cursor: pointer; /* 3 */
    *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
    cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
    *height: 13px; /* 3 */
    *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
    -webkit-appearance: textfield; /* 1 */ /* 2 */
    box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
    overflow: auto; /* 1 */
    vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
    border-collapse: collapse;
    border-spacing: 0;
}

html,
button,
input,
select,
textarea {
    color: #222;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

img {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.chromeframe {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

.image-news .img-4{
    width:30rem;
    margin-top:-10vh;
}
.image-news .img-3{
    height: 5rem;
    padding-left:15vw;
}

.image-news .img-2{
    width:20rem;
    height: 5rem;
    padding-left:15vw;
}
.image-news .img-1{
    width:8rem;
    height: 5rem;
}
.image-news{
    display: flex;
    justify-content: center;
    max-width: 80%;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
}
.br4 {
    background-position: -366px;
    width: 167px;
    min-width: 167px;
}
.br3{
    background-position: -287px;
width: 55px;
min-width: 55px;
}
.br2{
    background-position: -187px;
    width: 77px;
    min-width: 77px;
}
.br1{
    background-position: 0;
width: 165px;
min-width: 165px;
}
.br1,.br2,.br3,.br4{
    cursor: pointer;
    display: block;
    height: 32px;
    background-repeat: no-repeat;
    /* background-image: url('../social.svg'); */
    background-size: cover;
    margin-top: 45px;
    margin-left:auto;
    margin-right: auto;
    
}
.image-social{
    display: flex;
    justify-content: center;
    max-width: 80%;
    margin: 0 auto 75px auto;
    flex-direction: row;
    text-align: center;
}
#refLink{
    word-break: break-word;;
}
#WEB3_CONNECT_MODAL_ID{
    
}
.bg-img{
    background-repeat: repeat-x;
}
h1,
h2,
h3,
h4,
input {
    
    color: #182035;
    font-weight: bold;
}

p,
a,
span,
label,
h5,
h6 {
    
    text-decoration: none;
    color: #182035;
    font-weight: normal;
}

a:focus,
a:active {
    outline: none;
}

.body {
    /* background-image: url("../images/main_bg.html"); */
    background-repeat: no-repeat;
    background-color: #f4fdf9;
    background-size: cover;
    max-width: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    font-family: "Kanit-Regular";
}

/*--------------header---------------*/

.header {
    background: rgb(244 253 249);
    padding: 16px 0 19px;
    min-height: 46px;
    position: static;
    top: 0;
}

.container-info {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
}
video{
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}
.container-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.header .container-info,
.header {
    display: flex;
    align-items: center;
}

.btn-contact {
    border: 1px solid #20c1b2;
    box-sizing: border-box;
    border-radius: 30px;
    width: 130px;
    height: 45px;
    padding: 12px 30px;
    background: transparent;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.btn-contact:hover {
    background: rgba(32, 193, 184, 0.2);
    transition: 0.3s;
}

.btn-contact span {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: normal;
    color: #000;
    text-align: center;
    display: block;
}

.connect {
    margin-right: 20px;
}

.connect span {
    position: relative;
    padding-right: 16px;
}

.connect span:before {
    content: "";
    position: absolute;
    right: 0;
    width: 8px;
    height: 8px;
    /* background: url("../images/plus.svg") no-repeat; */
    background-size: cover;
    top: 50%;
    transform: translateY(-50%);
}

.item-link {
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 100%;
    width: 45px;
    height: 45px;
    display: block;
}

.item-link:hover {
    border: 1px solid rgba(255, 255, 255, 0.7);
    opacity: 0.7;
}

.contact-list {
    display: flex;
}

.item-list {
    margin-right: 4px;
}

.item-list:last-child {
    margin-right: 0;
}

.discord .item-link {
    /* background: url("../images/discord.svg") no-repeat; */
    background-size: 16px 16px;
    background-position: center;
}

.twitter .item-link {
    /* background: url("../images/twitter.svg") no-repeat; */
    background-size: 16px 13px;
    background-position: center;
}

.telegram .item-link {
    /* background: url("../images/telegram.svg") no-repeat; */
    background-size: 16px 13px;
    background-position: center;
}

.header-contacts {
    display: flex;
    align-items: center;
}

.container-btn-contacts {
    /* margin: 0 57px; */
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
}

/*----------first-screen------------*/

.main-title,
.main-title span {
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: normal;
    color: #000;
    max-width: 505px;
}

.main-title span {
    color: #20c1b2;
}

.sub-title-main,
.sub-title-main span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: normal;
    color: #ffffff;
}

.sub-title-main span {
    font-weight: bold;
}

.sub-title-main {
    margin: 20px 0 49px;
}

.pink-btn {
    /* background: linear-gradient(270deg, #20c1b2 0%, #078093 96.85%); */
    /*background-color: #40bb84;*/
    background-color: #000000;
    box-shadow: 0px 5px 25px rgba(32, 193, 184, 0.6), inset 0px -1px 0px rgba(0, 0, 0, 0.32);
    border-radius: 30px;
    width: 270px;
    height: 55px;
    border: none;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: normal;
    color: #ffffff;
}

.pink-btn:hover {
    /*
    background-color: #20bf55;
    background-image: linear-gradient(315deg, #20bf55 0%, #008f4f 74%);
    */
    background-color: #dbb72c;
    background-image: linear-gradient(315deg, #e8c951 0%, #ccd046 74%);
}

.screen-block {
    min-height: 552px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 80px;
}

.screen-block > div,
.right-screen > div {
    padding-right: 15px;
    padding-left: 15px;
    flex-grow: 1;
    flex-shrink: 1;
}

.block-result {
    background: #ffffff;
    border-radius: 6px;
    padding: 16px 25px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    min-width: 220px;
}

.block-result:last-child {
    margin-bottom: 0;
}

.result {
    margin-right: 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: normal;
    text-align: left;
}

.sub-text-result {
    font-size: 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 95px;
}

.pink-text {
    font-weight: bold;
    color: #20c1b2;
}

.bold-text {
    font-weight: bold;
}

.container-info-screen {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.container-first-screen {
    max-width: 100%;
    width: 100%;
}

.first-screen {
    padding-bottom: 82px;
}

.container-result {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

/*--------------calculate block--------*/

.header-section {
    margin-bottom: 62px;
    text-align: center;
}

.title-header {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: normal;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #40bb84;
}

.container-calculate-info {
    padding: 90px 30px 100px 50px;
    background: rgb(128, 186, 195);
    border-radius: 30px;
    min-height: 200px;
    max-width: 836px;
    margin: 0 auto;
}

.container-slider {
    position: relative;
    max-width: 600px;
    width: 90%;
}

.datalist {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
}

.ui-slider .slider-range-inverse {
    width: 100%;
    height: 13px;
    background: rgba(25, 32, 54, 0.1);
    border-radius: 50px;
    position: relative;
}

.ui-slider {
    /* background: linear-gradient(266.03deg, #20c1b2 24.24%, #078093 68.14%); */
    background-color: #40bb84;
    border-radius: 30px;
    height: 12px;
    position: absolute;
    left: 0;
    top: -22px;
    bottom: -20px;
    right: 0;
    border: 1px solid #e8e9eb;
}

.ui-state-default,
.ui-widget-content .ui-state-default {
    position: absolute;
    right: -6px;
    top: 50%;
    transform: translateY(-50%);
    border: 6px solid #ffffff;
    box-sizing: border-box;
    border-radius: 30px;
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.ui-slider .ui-slider-handle:before {
    content: "";
    position: absolute;
    left: -6px;
    right: -6px;
    top: -6px;
    bottom: -6px;
    width: 21px;
    height: 21px;
    border: 1px solid #bbbbbb;
    border-radius: 100%;
}

.mark {
    position: absolute;
    background: #ffffff;
    width: 50px;
    height: 30px;
    border-radius: 10px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #000000;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
}

.mark:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -13px;
    border: 8px solid transparent;
    border-top: 8px solid white;
}

.datalist li {
    
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #182035;
    opacity: 0.5;
}

.title-deposit {
    margin-bottom: 45px;
    font-weight: bold;
    font-size: 16px;
}

.ui-slider .slider-range-inverse {
    background: #e8e9eb;
    position: absolute;
    right: 0;
}

.ui-slider .ui-slider-range {
    background: transparent;
}
.deposit-block{
        width: 202px;
}
.amount-field {
    background: #f2f3f4;
    border: 1px solid #20c1b2;
    box-sizing: border-box;
    border-radius: 10px;
width: 100%;
    height: 50px;
    /* padding: 14px 23px 14px 20px; */
    position: relative;
    -webkit-user-select: text;
    cursor: auto;
    -webkit-appearance: textfield;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    
    display: flex;
    align-items: center;
}
.amount-field input{
    padding: 14px 55px 14px 20px;
    width: 100%;

}
.amount-field-button {
    /* content: "Max"; */
    z-index: 5;
    position: absolute;
    
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: normal;
    color: #20c1b2;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.amount-title {
    margin-bottom: 28px;
}

.container-counter {
    display: flex;
    justify-content: space-between;
    padding-bottom: 35px;
    position: relative;
}

.container-counter::after {
    content: "";
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    background: linear-gradient(266.61deg, #ffffff -1.47%, #20c1b2 46.7%, #ffffff 99.87%);
}

.deposit {
    width: 80%;
}

.amount-input {
    /* max-width: 116px; */
    background: transparent;
    border: none;
}

.list-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.item-info {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.footer-counter {
    margin-top: 35px;
    display: flex;
    flex-direction: column;
}

.invest-btn {
    margin: 0 auto;
    margin-top: 46px;
    width: 300px;
}

.title-info {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    margin-right: 8px;
}

.item-info p {
    font-weight: bold;
    font-size: 20px;
}

.item-info p.pink-text span {
    color: #000;
    font-weight: bold;
}

/*------------------dashboard-section------------*/

.dashboard-section {
    margin-top: 111px;
    padding-bottom: 98px;
}

.container-dashboard-info {
    background: rgb(128, 186, 195);
    border-radius: 30px;
    min-height: 200px;
    max-width: 890px;
    margin: 0 auto;
    padding: 42px 43px 39px 38px;
}

.item-dashboard > div {
    background: #ffffff;
    border-radius: 9px;
    padding: 14px 21px 18px;
    width: 158px;
    height: 53px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.item-dashboard {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    background-image: linear-gradient(266.03deg, #20c1b2 24.24%, #078093 68.14%);
    border-image: -moz-linear-gradient(266.03deg, #20c1b2 24.24%, #078093 68.14%);
    background: -webkit-linear-gradient(left, #20c1b2 0%, #078093 100%);
    box-sizing: border-box;
    padding: 1px;
    width: -webkit-fit-content;
    width: fit-content;
    width: -moz-fit-content;
}

.list-dashboard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 63px;
    position: relative;
}

.title-block-item {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 5px;
    line-height: 15px;
}

.info-item span {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
}

.list-dashboard::after {
    content: "";
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    background: linear-gradient(266.61deg, #ffffff -1.47%, #20c1b2 46.7%, #ffffff 99.87%);
}

.withdraw-btn {
    width: 210px;
    height: 45px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -23px;
}

.container-footer-dashboard {
    padding-top: 8px;
}

.title-footer-dashboard {
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    margin-bottom: 37px;
    position: relative;
    line-height: 24px;
}

.title-footer-dashboard span {
    font-weight: bold;
    margin-left: 5px;
}

.list-footer {
    display: flex;
    justify-content: space-between;
}

.item-footer {
    background: #f2f3f4;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.title-level {
    color: #000000;
    opacity: 0.7;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 4px;
}

.second-level {
    font-size: 14px;
}

.three-level {
    opacity: 0.8;
    font-size: 16px;
}

.item-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 14px 10px 8px;
    max-width: 240px;
    width: 100%;
    /* height: 42px; */
    justify-content: space-between;
}

.fourth-level {
    opacity: 0.9;
    font-size: 18px;
}

.fifth-level {
    opacity: 1;
    font-size: 20px;
}

.info-level {
    font-weight: normal;
    font-size: 10px;
}

/*------------------footer--------------*/

.container-logo-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.container-logo-footer span {
    font-weight: normal;
    font-size: 12px;
    color: #000;
    line-height: normal;
}

.container-logo-footer .logo-span {
    font-weight: bold;
}

.footer {
    min-height: 46px;
    position: relative;
    padding: 23px 0 31px;
}

.footer:before {
    content: "";
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    top: 0;
    border-radius: 100%;
    background: linear-gradient(266.61deg, #010727 -1.47%, #20c1b2 46.7%, #04010b 100%);
}

.footer-btn-contact {
    width: 192px;
    height: 45px;
    font-size: 14px;
}

.container-footer-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container-footer-btn {
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-between;
}

.footer .contact-list {
    margin-left: 30px;
}

.container-list-contact {
    display: flex;
    align-items: center;
}

/*-----------popup windows-------*/

.main-container-center {
    background: #ffffff;
    border-radius: 30px;
    height: auto;
    min-height: 500px;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    max-width: 970px;
    width: 100%;
    margin: 0 auto;
}

.faq-block {
    padding: 17px 25px 36px 69px;
}

.wrapper-dark {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.8);
    z-index: 10000;
    display: none;
    transition: 0.3s;
}
.container-faq {
    display: none;
    transition: 0.3s;
}

.active-faq {
    display: block;
    transition: 0.3s;
}

.title-window {
    font-weight: bold;
    font-size: 36px;
    line-height: normal;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000000;
    text-align: center;
}

.window-header {
    margin: 0 auto;
}

.title-faq-item {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: normal;
    /* text-transform: uppercase; */
    color: #000000;
}

.item-faq {
    padding: 30px 0 30px 0;
    position: relative;
}

.header-item-faq {
    position: relative;
    padding-right: 43px;
}

.container-info-faq {
    padding-top: 30px;
    display: none;
}

.container-info-faq p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: normal;
    color: #303030;
    margin-bottom: 15px;
    padding-right: 50px;
}

.container-info-faq p:last-child {
    margin-bottom: 0;
}

.item-faq::after {
    content: "";
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    background: linear-gradient(266.61deg, #ffffff -1.47%, #20c1b2 100%, #ffffff 100%);
}

.item-faq:last-child::after {
    display: none;
}

.close-faq-item {
    box-sizing: border-box;
    border-radius: 30px;
    width: 43px;
    height: 43px;
    /* background: url("../images/btn_arrow.svg") no-repeat; */
    border: none;
    background-size: contain;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s;
}

.active-btn-faq {
    transform: translateY(-50%) rotate(180deg);
    transition: 0.3s;
}

.close-window {
    position: absolute;
    width: 43px;
    height: 43px;
    /* background: url("../images/X.svg") no-repeat; */
    background-size: contain;
    box-sizing: border-box;
    border-radius: 100%;
    border: none;
    top: -50px;
    right: -50px;
}

.close-window:hover {
    opacity: 0.5;
}

.container-connect {
    background: #ffffff;
    border-radius: 30px;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    min-height: 307px;
    max-width: 570px;
    width: 100%;
    margin: 0 auto;
    display: none;
    transition: 0.3s;
}

.active-connect {
    display: block;
    transition: 0.3s;
}


.container-window {
    padding: 20px 20px 58px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.item-connect {
    padding: 17px 25px;
    border-radius: 20px;
    margin-bottom: 20px;
    position: relative;
    cursor: pointer;
    background: linear-gradient(270deg, #c12088 0%, #20C187 96.85%);
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.32);
    max-width: 172px;
    width: 100%;
    padding-right: 42px;
}

.list-connect {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 50px 0 0;
}

.item-connect p {
    font-weight: bold;
    font-size: 14px;
    line-height: normal;
    color: #ffffff;
}
.pink-btn a {
    font-weight: bold;
    font-size: 14px;
    line-height: normal;
    color: #ffffff;
}

.info-window {
    position: relative;
    padding-left: 24px;
    font-size: 10px;
}

.info-window:before {
    content: "";
    position: absolute;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    /* background: url("../images/asc_img.svg") no-repeat; */
    top: 50%;
    transform: translateY(-50%);
}

.metamask:before,
.binance:before {
    content: "";
    position: absolute;
    right: 8px;
    width: 34px;
    height: 34px;
    border-radius: 100%;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center;
    top: 50%;
    transform: translateY(-50%);
}

.binance:before {
  
    background-repeat: no-repeat;
    background-position: center;
}

/*--------------warn messages-----------*/

.warn-btn {
    color: #000003;
    font-size: 12px;
}

.warn-container {
    background: #ffffff;
    padding: 14px 0 7px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    min-height: 59px;
    z-index: 10;
}

.error-warn {
    background: #20c1b2;
}

.title-warn {
    font-weight: bold;
    font-size: 18px;
    line-height: normal;
    text-transform: uppercase;
    color: #000000;
}

.warn-container .container-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info-warn p {
    max-width: 972px;
    width: 100%;
    font-size: 12px;
    color: #303030;
}

.info-warn {
    max-width: 80%;
}

.error-warn .title-warn,
.error-warn .info-warn p,
.error-warn .warn-btn {
    color: #ffffff;
}

.error-warn .warn-btn {
    border: 1px solid #ffffff;
}

.error-warn .warn-btn:hover {
    background: rgba(255, 255, 255, 0.3);
}

.active-bg {
    display: block;
}

.mobile-open,
.mobile-fade,
.warn-container {
    display: none;
}

#warn.hidden {
    display: none;
}
#warn.fade-in {
    display: flex;
    transition: 0.3s;
}

button {
    position: relative;
    overflow: hidden;
}
button .spinner {
    position: absolute;
    top: 0;
    left: 50%;
    width: 32px;
    height: 100%;
    /* background-image: url("../images/spinner.svg"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    transform: translate(-50%, 0%);
    animation: spinner 1.5s linear 0s infinite;
    /* filter: brightness(50) grayscale(10) ; */
    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
    /* animation: openedMobileMenu 0.4s linear 0s 1 reverse backwards; */
}
@keyframes spinner {
    from {
        transform: rotate(0) translate(-50%, 0%) scaleX(0.1);
    }
    25% {
        filter: none;
    }
    50% {
        transform: rotate(0) translate(-50%, 0%) scaleX(1);
    }
    75% {
        filter: brightness(50) grayscale(10);
    }
    to {
        transform: rotate(0) translate(-50%, 0%) scaleX(0.1);
    }
}

.btn-copy{
    width: 24px;
    height: 24px;
    position: relative;
}
.btn-copy::after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-position: top center;
    filter: grayscale(1) opacity(.3);
    background-repeat: no-repeat;
}
.btn-copy:hover::after{
    filter: none;
    transition: .2s;
}
.btn-copy:active::after{
    filter: brightness(.9);
}
span.title-copy{
    position: absolute;
    color: #20c1b2;
    text-align: center;
    width: 100%;
    left: 0;
    bottom: 0;
    transform: translate(0, 80%);
    font-weight: 500;
}

.mainsection-content
{
  padding: 50px 45px;
}

.mainsection-content h1
{
  font-size: 5rem;
}
.header-holder
{
  border-bottom: 2px solid #dee2e6 !important;
  padding: 30px 0;
}

@media (min-width: 992px) and (max-width: 1200px)
{
  .mainsection-content h1 {
    font-size: 45px;
    font-weight: 400;
    line-height: 62px;
}
}
@media (min-width: 0px) and (max-width: 991px)
{
  .mainsection-content h1 {
    font-size: 36px;
    font-weight: 400;
    line-height: 50px;
}
}

.logo2
{
    padding: 36px 0 0 0;
  width: 100%;
}
.align-center
{
  align-self: center;
}
.content-section
{
  width: 60%;
}
.content-img
{
    width: 40%;
}

.mainsection-content
{
  display: flex;
  flex-wrap: wrap;
}
.head-border
{
    border-bottom: 2px solid #dee2e6 !important;
    padding: 26px 0;
}

.right-main-section, .right-main-section1 {
    overflow: scroll;
    border-radius: 16px;
    margin-bottom: 2.5rem;
}

.right-main-section {
    overflow: scroll;
    background: #80bac3;;
    box-shadow: 0 0 8px rgb(0 0 0 / 25%);
    padding: 1rem;
    /* min-height: 650px; */
    height: 545px;
}
.last-deposit-head {
    font-size: 1.43rem;
    color: #fff;
    margin: 1rem 0;
}

.deposit-holder::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: #545452;
}
.deposit-holder::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    border-radius: 10px;
    background-color: #f5f5f5;
}
.deposit-holder::-webkit-scrollbar {
    border-radius: 50px;
    width: 4px;
    background-color: #f5f5f5;
}
.deposit-item {
    background: #f4fdf9;
    box-shadow: 0 0 8px rgb(0 0 0 / 25%);
    border-radius: 16px;
    padding: 1rem;
}
.deposit-item-top .tx-hash {
    font-size: 14px;
    color: rgb(64 187 132);
}
.deposit-item-top .tx-date {
    font-size: 13px;
    color: #000;
}
.deposit-item-bottom .wallet-address {
    font-size: 14px;
    color: #000;
    margin: 0;
}
.deposit-item-bottom .amount {
    font-size: 12px;
    color: #000;
    margin: 0;
}
.deposit-item-bottom .plan {
    font-size: 12px;
    line-height: 24px;
    color: #000;
    margin: 0;
}
.deposit-item-bottom .plan .plan-number {
    color: rgb(128, 186, 195);
    font-weight: 700;
}
.align-items-center {
    align-items: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}
.gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
}
.d-flex {
    display: flex !important;
}
.mb-1 {
    margin-bottom: 0.25rem !important;
}


.gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
}
.container-deposit-info
{
    
    /* background: rgb(128, 186, 195) */
    border-radius: 30px;
    min-height: 200px;
    max-width: 998px;
    margin: 0 auto;
    /* padding: 42px 43px 39px 38px; */
  
}

.container-deposit-info .screen-block
{
  padding: 0;
  width: auto !important;
}
.dapp-img {
    height: 45px !important;
    width: 87px !important;
}
.footer-bottom-img.social {
    height: 35px;
    width: unset;
}
.footer-audit-box {
    /* background: #fff; */
    border-radius: 15px;
    width: 60%;
    margin: auto;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 42px;
}

.footer-bottom-img {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    cursor: pointer;
}

.justify-content-evenly {
    justify-content: space-evenly!important;
}

.flex-wrap {
    flex-wrap: wrap!important;
}
input[type='range'] {
    display: block;
  }
  
  input[type='range']:focus {
    outline: none;
  }
  
  #inputDiv {
    width: 500px;
    margin: 150px auto;
    position: relative;
  }
  
  #etiqueta {
    width: 30px;
    height: 26px;
    position: absolute;
    z-index: 9;
    background-color: HotPink;
    color: white;
    font-size: 12px;
    text-align: center;
    padding-top: 7px;
    top: -45px;
  }
  
  #etiqueta:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    z-index: 8;
    background-color: HotPink;
    position: relative;
    left: 9px;
    /*top:-1px;*/
  }
  /* 2 */
  
  input[type='range'],
  input[type='range']::-webkit-slider-runnable-track,
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  /* 2.1 THUMB */
  
  input[type=range]::-webkit-slider-thumb {
    background-color: #fff;
    width: 20px;
    height: 20px;
    border: 3px solid #20C187;
    border-radius: 50%;
    margin-top: -9px;
    -webkit-appearance: none;
  }
 
  input[type=range]::-webkit-slider-runnable-track {
    background-color: #fff;
    height: 3px;
  }
  
  input[type=range]:focus::-webkit-slider-runnable-track {
    outline: none;
  }
  
  input[type=range]::-moz-range-track {
    background-color: #555;
    height: 3px;
  }
  
  input[type=range]::-ms-track {
    background-color: #555;
    height: 3px;
  }
  
  input[type=range]::-ms-fill-lower {
    background-color: #008f4f
  }
  
  input[type=range]::-ms-fill-upper {
    background-color:#008f4f ;
  }
  
  .form-control {
    display: block;
    width: 100%;
    padding: 2px 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    /* background-color: #f4fdf9; */
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.form-control:focus
{
    color: #80bac3;;
    background-color: #f4fdf9;
    border-color: currentColor;
    border-color: initial;
    outline: 0;
    box-shadow: 0 0 0 0rem rgb(13 110 253 / 25%);
}

.calculate-block-container
{
    padding: 30px 0 80px 0;
}
@media (min-width: 0px) and (max-width: 549px)
{
    .invest-btn
{
  width: 100%;
}
.container-calculate-info
{
    padding: 35px;
}
.deposit-block
{
    width: 100%;
}
.container-slider
{
    width: 100%;
}
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    /* background: #d3d3d3; */
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
  }
  
  /* .slider:hover {
    opacity: 1;
  } */
  
  input[type = range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
      width: 22px;
    height: 22px;
    border-radius: 50%;
    background: #12161c;
    cursor: pointer;
  }
  
  input[type = range]::-moz-range-thumb {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: #12161c;
    cursor: pointer;
  }
  .slider::-ms-fill-lower { 
    background: #000;
  }
  @media (min-width: 655px) and (max-width: 767px)
  {
    .container-counter
    {
        display: block;
    }
  }


  /*Chrome*/

  input[type="range"] { 
    margin: auto;
    -webkit-appearance: none;
    position: relative;
    overflow: hidden;
    height: 25px;
    width: 100%;
    cursor: pointer;
    border-radius: 8px;
  }
  
  ::-webkit-slider-runnable-track {
    background: #fff;
  }
  
  /*
  * 1. Set to 0 width and remove border for a slider without a thumb
  */
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px; /* 1 */
    height: 40px;
    background: #fff;
    box-shadow: -100vw 0 0 100vw #40bb84;
    border-radius: 50%;
    -webkit-transition: 2s;
    transition: 2s;
   
  }
  
  ::-moz-range-track {
    height: 40px;
    background: #fff;
  }
  
  ::-moz-range-thumb {
    background: #fff;
    height: 40px;
    width: 20px;
    border-radius: 0 !important;
    box-shadow: -100vw 0 0 100vw #40bb84;
    box-sizing: border-box;
    border-radius: 50%;
    -moz-transition: 2s;
    transition: 2s;
  }
  
  ::-ms-fill-lower { 
    background: #2ABA4C;
    
  }
  
  ::-ms-thumb { 
    background: #fff;
    height: 40px;
    width: 20px;
    box-sizing: border-box;
    border-radius: 50%;
  }
  
  ::-ms-ticks-after { 
    display: none; 
  }
  
  ::-ms-ticks-before { 
    display: none; 
  }
  
  ::-ms-track { 
    background: #fff;
    color: transparent;
    border: none;
    border-radius: 50%;
  }
  
  ::-ms-tooltip { 
    display: none;
  }

  .selectlayoutadjustment
  {
    width: 50%;
  }

  .popupbackground
  {
    background-color: #f0d87b !important;
  }
  .ml-15
  {
    margin-left: 15px;
  }




 
/* For Tablet View */
@media screen and (min-device-width: 768px)
and (max-device-width: 1024px) {
  .autoresizeaffiliate {
    transform: scale(0.8);
  }
}
 
/* For Mobile Portrait View */
@media screen and (max-device-width: 480px)
and (orientation: portrait) {
  .autoresizeaffiliate {
    transform: scale(0.7);
  }
}
 
/* For Mobile Landscape View */
@media screen and (max-device-width: 640px)
and (orientation: landscape) {
  .autoresizeaffiliate {
    transform: scale(0.7);
  }
}
 
/* For Mobile Phones Portrait or Landscape View */
@media screen and (max-device-width: 640px) {
  .autoresizeaffiliate {
    transform: scale(0.6);
  }
}
 
/* For iPhone 4 Portrait or Landscape View */
@media screen and (min-device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
  .autoresizeaffiliate {
    transform: scale(0.5);
  }
}
 
/* For iPhone 5 Portrait or Landscape View */
@media (device-height: 568px) and (device-width: 320px)
and (-webkit-min-device-pixel-ratio: 2) {
  .autoresizeaffiliate {
    transform: scale(0.6);
  }
}
 
/* For iPhone 6 and 6 plus Portrait or Landscape View */
@media (min-device-height: 667px) and (min-device-width: 375px)
and (-webkit-min-device-pixel-ratio: 3) {
  .autoresizeaffiliate {
    transform: scale(0.6);
  }
}

/* For Desktop View */
@media screen and (min-width: 1024px) {
  .autoresizeaffiliate {
    transform: scale(1);
  }
}
